<template>
	<div>
		<div class="row">
	        <div class="col-10">
				<e-select
					id="entity_id"
					v-model="entity_selected"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('tiers.rechercher_billing_entity')"
					:selectedLabel="$t('global.selected_label')"
					:options="entity"
					:searchable="true"
					:allow-empty="true"
					:loading="isLoadingEntity"
					:show-labels="false"
					:multiple="true"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

	        <div class="col-2">
	            <button @click.prevent="loadInvoicesSupplier" class="btn btn-block btn-primary">{{ $t('global.rechercher') }}</button>
	        </div>
		</div>

		<vueDropzone 
			v-if="dropzone_loaded"
			ref="dropzone" 
			id="dropzone" 
			:options="dropzoneOptions"
			@vdropzone-success-multiple="onSuccess"
			@vdropzone-error="onError"
			@vdropzone-file-added="onAdded"
			:useCustomSlot=true
		>
			<CustomTable
	            id_table="invoice_suppliers_ocr_control"
	            ref="invoice_suppliers_ocr_control"
	            :items="invoices"
	            :busy.sync="table_busy"
	            primaryKey="suppliersocr_id"
	            :hide_if_empty="true"
	            :hrefsRoutes="config_table_hrefs"
				:externSlotColumns="extern_slot_columns"
	        >
	            <template v-slot:[`custom-slot-cell(suppliersocr_num)`]="{ data }">
	                <i v-if="data.suppliersocr_ocr_status == 'waiting'">{{ $t('invoice.supplier_waiting') }}</i>
	                <a v-else href="" class="" @click.prevent="quickPreview(data.suppliersocr_pdf_url)">
					    {{ data.suppliersocr_num }} <font-awesome-icon :icon="['fas', 'eye']" />
	                </a>
				</template>
	            <template v-slot:[`custom-slot-cell(suppliersocr_has_duplicate)`]="{ data }">
	            	<font-awesome-icon v-if="data.suppliersocr_has_duplicate" role="button" class="text-danger" :icon="['fas', 'external-link']" @click="openDuplicate(data.suppliersocr_id)"/>
					<font-awesome-icon class="text-success" v-else :icon="['fal', 'times-circle']" />
				</template>
			</CustomTable>
		</vueDropzone>

        <div v-if="upload_mode" class="col-12 mt-3 text-center">
            <button @click="save" class="btn btn-primary">{{ $t('global.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" spin /></button>
        </div>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="pdf_url = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="pdf_url != null" :src="pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
	</div>
</template>

<style>
	.dropzone {
		background: inherit;
    	border: none;
    	padding: 0;
	}
	.vue-dropzone:hover {
	    background-color: inherit;
	}
	.dropzone .dz-message {
		text-align: inherit;
	}
</style>

<script type="text/javascript">
	import { EventBus } from "EventBus"
    import Tiers from "@/mixins/Tiers.js"
	import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"
    import Config from "@/mixins/Config.js"
	import _cloneDeep from 'lodash/cloneDeep'
	import Constants from 'Constants'
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'

	export default {
		name: 'ControlList',
		mixins: [Tiers, Invoice, Navigation, Shutter, ShutterInvoice, Config],
		props: ['tiers_id'],
		data () {
			return {
				entity_selected: null,
				entity: [],
				isLoadingEntity: false,
				invoices: [],
				table_busy: false,
                events_tab: {
                    'TableAction::goToAddInvoiceSupplier': () => { 
                    	this.openAddInvoiceSupplier(this.tiers_id)
                    },
                    'TableAction::goToControlInvoiceSupplier': this.setupInvoiceSupplierControlForm,
                    'TableAction::goToDeleteInvoiceSupplier': this.goToDeleteInvoiceSupplier,
                },
				extern_slot_columns: ['suppliersocr_num', 'suppliersocr_has_duplicate'],
                config_table_hrefs: {
                    'entity.tiers_rs': {
                        routeUniqueName: 'entityTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'entity.tiers_id'
                        }
                    },
                    'tier.tiers_rs': {
                        routeUniqueName: 'tiersTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'tier.tiers_id'
                        }
                    },
                },
                pdf_url: null,
                upload_mode: false,
                processing: false,
				dropzone_loaded: false,
				dropzoneOptions: {
					thumbnailWidth: 250,
					acceptedFiles: 'image/*,application/pdf',
					autoProcessQueue: false,
					uploadMultiple: true,
					clickable: false,
					dictDefaultMessage: this.getTrad('invoice.upload_invoice_supplier'),
					headers: {
						"Authorization" : "Bearer " + this.getConfig('token'),
						withCredentials: true
					}
		      	}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.isLoadingEntity = true
				const accounting = await this.loadAccountingPlans()
				const accounting_supplier = accounting.filter(acc => acc.accountingplan_supplier)
				this.entity = accounting_supplier.map(acc => acc.tiers)
				let prev_selection = this.getConfig('invoice_suppliers_entities')
				this.entity_selected = prev_selection ? this.entity.filter(en => prev_selection.includes(en.tiers_id)) : _cloneDeep(this.entity)
				this.isLoadingEntity = false

				this.loadInvoicesSupplier()


				this.dropzoneOptions.url = this.getUrlInvoiceSupplierTiers(this.tiers_id)
				if(this.tiers_id) {
					this.dropzoneOptions.url = this.getUrlInvoiceSupplierTiers(this.tiers_id)
				}
				else {
					this.dropzoneOptions.url = Constants.INVOICES_SUPPLIERS_OCR_URL + "?licence_key="+Constants.USER_LICENCE_KEY
				}
				this.dropzone_loaded = true
			},

			async loadInvoicesSupplier() {
				if(this.$refs.invoice_suppliers_ocr_control) {
					this.$refs.invoice_suppliers_ocr_control.unselectAll()
				}
				let entity_ids = []
				if(this.entity_selected && this.entity_selected.length !== 0) {
					entity_ids = this.entity_selected.map(tiers => tiers.tiers_id)
					this.setConfig('invoice_suppliers_entities', entity_ids)
				}

				this.table_busy = true
				if(this.tiers_id) {
					this.invoices = await this.getInvoiceSupplierControlByTiers(this.tiers_id, entity_ids)
				}
				else {
					this.invoices = await this.getInvoiceSupplierControl(entity_ids)
				}
				this.table_busy = false
			},

			async goToDeleteInvoiceSupplier(invoice) {
				await this.deleteInvoiceSupplier(invoice.suppliersocr_id)
				EventBus.$emit("TableAction::stopSpin")
				this.successToast('toast.info_save_succes')
				this.loadInvoicesSupplier()
			},	

            async quickPreview(pdf_url) {
                this.$refs.modelPreview.show()
                this.pdf_url = pdf_url
            },

            async openDuplicate(id) {
            	// let duplicate = this.invoices.filter(invoice => invoice.suppliersocr_num == num && invoice.suppliersocr_ttc == ttc).slice(0, 2)
            	let duplicate = await this.getDuplicationInvoiceSupplier(id)

            	this.setUpControlDuplicate(duplicate)
            },

			async save() {
				if(this.processing) return false

				this.processing = true
				this.$refs.dropzone.processQueue()
			},

			onSuccess() {
				this.processing = false
				this.successToast('toast.info_save_succes')
				this.loadInvoicesSupplier()
				this.dropzone_loaded = false
				this.$nextTick(() => {
					this.dropzone_loaded = true
				})
				this.upload_mode = false
			},

			onError() {
				this.processing = false
				this.failureToast("toast.info_save_failed")
			},

			onAdded() {
				this.upload_mode = true
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			vueDropzone: vue2Dropzone
		}
	}

</script>